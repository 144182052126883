import React from "react";
import { Link } from "react-router-dom";

/**
 * GetStartedCard component displays a card with a title and description.
 * When clicked, it navigates to a questionnaire page based on the title.
 * The title is formatted to be URL-friendly (spaces are replaced with hyphens,
 * and '&' is replaced with 'and').
 *
 * Props:
 * - title: The title of the card to display.
 * - description: The description providing additional information about the card.
 */

const GetStartedCard = ({ title, id,description }) => {
  
  // Function to format the title for URL usage
  const formatTitleForURL = (title) => {
    return title
      .replace(/ /g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with 'and'
      .replace(/or/g, "or"); // Optionally replace 'or' if needed
  };

  const formattedTitle = formatTitleForURL(title);

  return (
    <div>
      <Link to={`/questionnaire/${formattedTitle}/${id}`}>
        <div className="health-card">
          <h2 className="health-card-title">{title}</h2>
          <div className="health-card-content">
            <p className="health-card-description">{description}</p>
            <div className="health-card-icon">➔</div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GetStartedCard;
