import React from "react";
import {Link } from "react-router-dom";

/**
 * SucessfullReset Component
 *
 * This component is responsible for displaying a success message
 * to the user once the password reset works.
 *
 * @component
 * @example
 * return (
 *   <SuccessfulReset />
 * )
 */

const SuccessfulReset = () => {

  return (
    <div className="login-wrapper reset-message">
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <div className="reset-link-sent-card">
                <p>
                Your password has been successfully reset. You can now log in with your new password.
                </p>
                <Link to="/users/login" className="btn btn-primary">
                  Go to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessfulReset;
