import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * login page
 * It allows users to log in by entering their email and password.
 * The page includes email and password validation, and upon successful login,
 * the user is navigated to the dashboard. It also logs key user events such as
 * login attempts, validation errors, and successful logins to the server.
 * The Helmet component is used to manage the document head for SEO purposes.
 */

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [backendError, setBackendError] = useState("");
  const [loading, setLoading] = useState(false);

  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const navigate = useNavigate();

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required."); // Set error if email is empty
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format."); // Set error if email format is invalid
      return false;
    } else {
      setEmailError(""); // Clear the error if email is valid
      return true;
    }
  };

  const validatePassword = () => {
    if (!password) {
      setPasswordError("Password is required."); // Set error if password is empty
      return false;
    } else {
      setPasswordError(""); // Clear the error if password is valid
      return true;
    }
  };

  // Function to handle form submission method 2
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      try {
        await api.get("/sanctum/csrf-cookie");

        //Send login request with email and password
        const response = await api.post("/login", { email, password });
        const { panel } = response.data;

        // Redirect to the panel URL
        window.location.href = panel.url;
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle 422 error (unprocessable content) - credentials error
          const errorMessage =
            error.response.data.errors?.email?.[0] ||
            "Invalid credentials. Try again.";
          setBackendError(errorMessage);
        } else {
          console.log(error);
          setBackendError("Server error. Please try again.");
          navigate("/page/error");
        }
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  };

  return (
    <div className="login-wrapper">
      <Helmet>
        <title>Balanced | Login To Dashboard</title>
      </Helmet>
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo-white.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <p className="login-body-title">Welcome Back</p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="text"
                    className={`form-control ${
                      emailError ? "is-invalid InputError" : ""
                    }`}
                    id="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="password">Password*</label>
                  <input
                    type="password"
                    className={`form-control ${
                      passwordError ? "is-invalid InputError" : ""
                    }`}
                    id="password"
                    placeholder="Enter Your Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                </div>
                {backendError && (
                  <div className="alert alert-danger">{backendError}</div>
                )}
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary btn-block login_pagebtn"
                >
                  {loading ? "Logging in..." : "Login"}
                </button>
              </form>
              <div className="login-footer">
                <p>
                  Not A Member? <Link to="/quizzes">Sign Up</Link>
                </p>
                <p>
                  <Link to="/users/password/new">Forgot Password?</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
