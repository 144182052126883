import React from "react";
import { Link } from "react-router-dom";

/**
 * TreatmentCard component displays an individual treatment option.
 * It formats the treatment name for URL navigation and provides a link to the questionnaire page.
 */

function TreatmentCard(props) {
  const authState = JSON.parse(localStorage.getItem("authState"));
  const isAuthenticated = authState && authState.isAuthenticated === true;
  const authRedirectUrl = `${process.env.REACT_APP_URL}/patient/treatment-plans/create`;
  // Function to format the title for URL usage
  const formatTitleForURL = (title) => {
    return title
      .replace(/ /g, "-") // Replace spaces with hyphens
      .replace(/&/g, "and") // Replace & with 'and'
      .replace(/or/g, "or"); // Optionally replace 'or' if needed
  };

  const formattedTitle = formatTitleForURL(props.treatment_name);
  return (
    <div>
      <Link
        to={
          isAuthenticated
            ? authRedirectUrl
            : `questionnaire/${formattedTitle}/${props.treatment_id}`
        }
        style={{ color: "unset" }}
      >
        <div className="treatment_card">
          <div className="card_body">
            <div className="card_top">
              <div className="card_img">
                {/* <img src={require(`..//assets/images/${props.image}`)} alt="Mockup" /> */}
                <img
                  src={require(`..//assets/images/anti_ageing_vitality.jpg`)}
                  alt="Mockup"
                />
              </div>
            </div>
            <div className="card-bottom">
              <div className="card-bottom-text">
                <h6 className="treatment-name text-align-center">
                  {props.treatment_name}
                </h6>

                <div className="treatment-info d-flex">
                  <p className="treatment-description">
                    {props.treatment_description}
                  </p>
                  <img
                    src={require("..//assets/images/roundArrow.png")}
                    alt="Right Arrow"
                    className="roundedRight-arrow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default TreatmentCard;
