import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

/**
 * Error Page
 *
 * This component is responsible for displaying a error message
 * to the user incase an error occured on the website
 *
 * @page
 * @example
 * return (
 *   <ErrorPage />
 * )
 */

const ErrorPage = () => {
  const handleRedirect = () => {
    window.location.href = "/";
  };
  return (
    <div className="login-wrapper reset-message">
      <Helmet>
        <title>Balanced | Application Error</title>
      </Helmet>
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <div className="reset-link-sent-card">
                <h3 className="text-center mb-3">
                  Oops! Something went wrong.
                </h3>
                <p>
                  We're sorry, but an error occurred while processing your
                  request.If the error continues please contact us{" "}
                  <a href="mailto:support@balanced.com.au">
                    support@balanced.com.au
                  </a>
                </p>
                <Link onClick={handleRedirect} className="btn btn-primary">
                  Back to home page{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
