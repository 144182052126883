import React, { useState, useEffect } from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import TreatmentCard from "./TreatmentCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import api from "../Api/AuthApi";
import { useNavigate } from "react-router-dom";
/**
 * TreatmentsSwiper component displays a Swiper carousel of treatment cards.
 */

function TreatmentsSwiper() {
  const [treatmentCards, setTreatmentCards] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTreatmentCards = async () => {
      try {
        const response = await api.get("/api/treatments");
        console.log("Treatments", response.data.data);
        setTreatmentCards(response.data.data);
      } catch (error) {
        console.error("Error fetching treatments:", error);
        navigate("/page/error");
      }
    };

    fetchTreatmentCards();
  }, [navigate]);

  return (
    <div className="treatments_swiper">
      <div className="container">
        <div className="row">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Scrollbar]}
            scrollbar={{ draggable: true }}
            breakpoints={{
              740: {
                slidesPerView: 2,
              },
              940: {
                slidesPerView: 2,
              },
              1100: {
                slidesPerView: 4,
              },
            }}
          >
            {treatmentCards.map((treatment) => (
              <SwiperSlide key={treatment.id}>
                <TreatmentCard
                  image={treatment.image}
                  treatment_name={treatment.name}
                  treatment_description={treatment.description}
                  treatment_id={treatment.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default TreatmentsSwiper;
