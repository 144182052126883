import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * ForgotPassword Component
 *
 * This component handles the "Forgot Password" functionality where a user can request
 * a password reset by providing their registered email. It includes frontend validation,
 * backend API interaction, and user feedback for errors.
 *
 * @component
 * @example
 * return (
 *   <ForgotPassword />
 * )
 */

const ForgotPassword = () => {
  // State variables to manage email input, errors, and navigation
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [backendError, setBackendError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Regular expression to validate email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  /**
   * validateEmail
   *
   * Function to validate the entered email format and check if it is not empty.
   * Provides user feedback for invalid or missing email.
   *
   * @returns {boolean} True if the email is valid, otherwise false.
   */

  const validateEmail = () => {
    if (!email) {
      setEmailError("Email is required.");
      return false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format.");
      return false;
    } else {
      setEmailError("");
      return true;
    }
  };

  /**
   * handleSubmit
   *
   * Handles form submission to initiate the password reset process. It performs email
   * validation, interacts with the backend, and handles success/error responses.
   *
   * @param {object} e Event object
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    const isEmailValid = validateEmail();
    if (isEmailValid) {
      setLoading(true);
      try {
        await api.get("/sanctum/csrf-cookie");
        const response = await api.post(
          "/forgot-password",
          {
            email,
          }
        );

        console.log(response);

        navigate("/users/reset-link-sent");
      } catch (error) {
        if (error.response && error.response.status === 422) {
          // Handle 422 error (unprocessable content) - credentials error
          const errorMessage =
            error.response.data.errors?.email?.[0] ||
            "Invalid credentials. Try again.";
          setBackendError(errorMessage);
        } else {
          setBackendError("Failed to send reset link. Please try again");
          navigate("/page/error");
        }
      }finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <div className="login-wrapper password-wrapper">
      <Helmet>
        <title>Balanced | Reset Password</title>
      </Helmet>
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo-white.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <p className="login-body-title password-body-title">
                Create a new password
              </p>
              <p className="password-body-description">
                Please enter your email below to reset your password.
              </p>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className={`form-control ${
                      emailError ? "is-invalid InputError" : ""
                    }`}
                    id="email"
                    placeholder="Enter Your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                  {backendError && (
                  <div className="alert alert-danger mt-3">{backendError}</div>
                )}
                </div>
                <button
                  type="submit"
                  disabled={loading}
                  className="btn btn-primary btn-block login_pagebtn"
                >
                  {loading ? "Sending Reset Link..." : "Send Reset Link"}
                </button>
              </form>
              <div className="login-footer">
                <p>
                  Remembered? <Link to="/users/login">Login</Link>
                </p>
                <p>
                  <Link to="/quizzes">Sign Up</Link> for an account
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
