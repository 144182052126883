import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

/**
 * HowitworksCard component that displays a step-by-step process of how the service works.
 * The component consists of animated cards that describe each step in the process 
 **/

function HowitworksCard() {
  return (
    <div className="howItWorks_Card">
      <div className="row">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-6" data-aos="fade-up" data-aos-duration="800">
              <div className="howItWorks_card_img"></div>
            </div>
            <div className="col-lg-6 process_column">
              <div className="process">
                <div className="process_line" data-aos="fade-up" data-aos-duration="800">
                  <img
                    src={require("..//assets/images/workProcess.PNG")}
                    alt=""
                  />
                </div>
                <div className="process_card" data-aos="fade-up" data-aos-duration="820">
                  <div className="details_wrapper">
                    <h4 className="process_name">Register</h4>
                    <p className="process_description">
                      Register as a balanced patient and complete a medical
                      questionnaire.
                    </p>
                  </div>
                </div>
                <div className="process_card" data-aos="fade-up" data-aos-duration="830">
                  <div className="details_wrapper" data-aos="fade-up">
                    <h4 className="process_name">Fill in the questionnaire</h4>
                    <p className="process_description">
                      Register as a balanced patient and complete a medical
                      questionnaire.
                    </p>
                  </div>
                </div>

                <div className="process_card" data-aos="fade-up" data-aos-duration="840">
                  <div className="details_wrapper">
                    <h4 className="process_name">Patient care call</h4>
                    <p className="process_description">
                      Register as a balanced patient and complete a medical
                      questionnaire.
                    </p>
                  </div>
                </div>
                <div className="process_card" data-aos="fade-up" data-aos-duration="840">
                  <div className="details_wrapper">
                    <h4 className="process_name">Place Your Order</h4>
                    <p className="process_description">
                      Register as a balanced patient and complete a medical
                      questionnaire.
                    </p>
                  </div>
                </div>
                <div className="process_card" data-aos="fade-up" data-aos-duration="850">
                  <div className="details_wrapper" >
                    <h4 className="process_name">Client Collaboration</h4>
                    <p className="process_description">
                      Register as a balanced patient and complete a medical
                      questionnaire.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowitworksCard;
