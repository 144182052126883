import React from "react";
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import DoctorsCard from "./DoctorsCard";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

/**
 * TreatmentsSwiper component displays a Swiper carousel of treatment cards.
 */

function DoctorsSwiper() {
  const doctorsList = [
    {
      image: "Default_Doctor_uncopped_dark_teal_color_mood_0.jpg",
      doctor_name: "John Doe",
      doctor_role: "Doctor",
    },
    {
      image: "Default_Doctor_uncopped_dark_teal_color_mood_1.jpg",
      doctor_name: "Alex Doe",
      doctor_role: "Doctor",
    },
    {
      image: "Default_Doctor_uncopped_dark_teal_color_mood_2.jpg",
      doctor_name: "Jonny Doe",
      doctor_role: "Nurse",
    },
  ];
  return (
    <div className="testimonials_section">
      <div className="row">
        <div className="col-lg-12">
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            modules={[Scrollbar]}
            scrollbar={{ draggable: true }}
            breakpoints={{
              760: {
                slidesPerView: 2,
              },
              1080: {
                slidesPerView: 3,
              },
            }}
          >
            {doctorsList.map((doctor, index) => (
              <SwiperSlide key={index}>
                <DoctorsCard
                  image={require(`..//assets/images/${doctor.image}`)}
                  doctor_name={doctor.doctor_name}
                  doctor_role={doctor.doctor_role}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default DoctorsSwiper;
