import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import api from "../Api/AuthApi";
/**
 *Contact Us Form Page
 */

function ContactUs() {
  const [loading, setLoading] = useState(false);
  // State to store form data
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "+61",
    assistance: "",
    additionalInfo: "",
  });

  const navigate = useNavigate();
  // State to store form validation errors
  const [errors, setErrors] = useState({});

  //function to check email validation
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  //function to check & validate form input fields
  const validateForm = () => {
    let formErrors = {};
    if (!formData.firstName) formErrors.firstName = "First name is required";
    if (!formData.lastName) formErrors.lastName = "Last name is required";
    if (!formData.email) {
      formErrors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      formErrors.email = "Invalid email address";
    }
    if (!formData.phone || formData.phone === "+61 ") {
      formErrors.phone = "Phone number is required";
    } else if (!formData.phone.startsWith("+61 4")) {
      formErrors.phone = "Phone number must start with '4' or '04'.";
    } else if (formData.phone.length < 12) {
      formErrors.phone = "Invalid Phone number";
    }

    if (!formData.assistance) formErrors.assistance = "Please select an option";
    if (!formData.additionalInfo)
      formErrors.additionalInfo = "Please explain your problem";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    let input = value;

    if (!input.startsWith("+61 ")) {
      input = "+61 ";
    }

    let numberPart = input.slice(4).replace(/[^0-9]/g, "");

    if (numberPart.startsWith("0") && numberPart.length > 1) {
      numberPart = numberPart[1] + numberPart.slice(2);
    }

    if (numberPart.length > 9) {
      numberPart = numberPart.slice(0, 9);
    }

    const formattedNumber =
      "+61 " + numberPart.replace(/(\d{3})(\d{3})(\d{3})?/, "$1 $2 $3").trim();

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field cannot be empty.",
      }));
    }

    setFormData((prevData) => ({
      ...prevData,
      phone: formattedNumber,
    }));
  };

  // Function to handle input field changes and update the form state
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field cannot be empty.",
      }));
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        await api.get("/sanctum/csrf-cookie");

        const response = await api.post("/api/contact", {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          assistance_type: formData.assistance,
          message: formData.additionalInfo,
        });

        if (
          response.data.message === "Contact request submitted successfully"
        ) {
          navigate("/contact-us/message-sent");
        }
      } catch (error) {
        console.error("There was an error submitting the form!", error);
        navigate("/page/error");
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Balanced | Contact Our Team</title>
      </Helmet>
      <Header />
      <div className="contact_us_page">
        <div className="contact-us-hero-header-wrapper">
          <div className="container">
            <div className="contact-us-hero-content">
              <h2 className="contact-us-hero-title">Get In Touch Today</h2>
              <p className="contact-us-hero-description">
                If you'd like to get in touch with our team here at balanced,
                fill out the form and we'll be with you shortly.
              </p>
            </div>
          </div>
        </div>

        <main>
          <section className="contact-form-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <form
                    className="contact-form-wrapper"
                    onSubmit={handleSubmit}
                  >
                    <div className="row mb-4">
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="firstName">
                            First name
                          </label>
                          <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            className={`form-control ${
                              errors.firstName ? "is-invalid" : ""
                            }`}
                            placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleChange}
                          />
                          {errors.firstName && (
                            <small className="text-danger">
                              {errors.firstName}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="lastName">
                            Last name
                          </label>
                          <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            className={`form-control ${
                              errors.lastName ? "is-invalid" : ""
                            }`}
                            placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                          />
                          {errors.lastName && (
                            <small className="text-danger">
                              {errors.lastName}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className={`form-control ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="phone">
                        Phone
                      </label>
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className={`form-control ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="+61 4XX XXX XXX"
                        value={formData.phone}
                        onChange={handlePhoneChange}
                      />
                      {errors.phone && (
                        <small className="text-danger">{errors.phone}</small>
                      )}
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="assistance">
                        How can we assist you?
                      </label>
                      <select
                        className={`form-select ${
                          errors.assistance ? "is-invalid" : ""
                        }`}
                        id="assistance"
                        name="assistance"
                        value={formData.assistance}
                        onChange={handleChange}
                      >
                        <option value="" className="opt">
                          Open this select menu
                        </option>
                        <option value="General Health Inquiry">
                          General Health Inquiry
                        </option>
                        <option value="Ask About Our Services">
                          Ask About Our Services
                        </option>
                        <option value="Billing & Payment Inquiries">
                          Billing & Payment Inquiries
                        </option>
                        <option value="Update Personal or Medical Information">
                          Update Personal or Medical Information
                        </option>

                        <option value="Technical Support">
                          Technical Support
                        </option>
                        <option value="Submit a Complaint">
                          Submit a Complaint
                        </option>
                      </select>
                      {errors.assistance && (
                        <small className="text-danger">
                          {errors.assistance}
                        </small>
                      )}
                    </div>

                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="additionalInfo">
                        Additional information to assist you
                      </label>
                      <textarea
                        className={`form-control ${
                          errors.additionalInfo ? "is-invalid" : ""
                        }`}
                        id="additionalInfo"
                        name="additionalInfo"
                        rows="4"
                        placeholder="Write down your message"
                        value={formData.additionalInfo}
                        onChange={handleChange}
                      ></textarea>
                      {errors.additionalInfo && (
                        <small className="text-danger">
                          {errors.additionalInfo}
                        </small>
                      )}
                    </div>

                    <button
                      type="submit"
                      disabled={loading}
                      className="submitBtn w-100"
                    >
                      {loading ? "Sending..." : "SUBMIT"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
}

export default ContactUs;
