import React from "react";
import { Link } from "react-router-dom";

/**
 * FaqCategoryCard Component
 *
 * This component displays a card for an FAQ category, with an icon, title, and description.
 * Each card links to a specific FAQ category page and scrolls smoothly to the top when clicked.
 *
 * Props:
 * - title: The title of the FAQ category.
 * - description: A short description of the category.
 * - image: URL of the category icon image.
 */

function FaqCategoryCard(props) {

  // Smoothly scrolls to the top of the page when the card is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <div className="faq-category-card">
      <Link to={`/support/${props.title}`} onClick={scrollToTop}>
        <div className="faq-category-card-content">
          <div className="faq-category-card-content-icon">
            <img src={props.image} alt="Icon" />
          </div>
          <h3 className="faq-category-card-content-title">{props.title}</h3>
          <p className="faq-category-card-content-desciption">
            {props.description}
          </p>
        </div>

        <div className="faq-category-card-arrow">
          <img
            src={require("..//assets/images/roundArrow.png")}
            alt="Round Arrow"
          />
        </div>
      </Link>
    </div>
  );
}

export default FaqCategoryCard;
