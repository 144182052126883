import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * Pricing Page
 * This pages lists all the treatments with the prices
 * Each card component navigates to the quiz page for the sellected treatment
 */

function Pricing() {
  const [treatmentCards, setTreatmentCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const authState = JSON.parse(localStorage.getItem("authState"));
  const isAuthenticated = authState && authState.isAuthenticated === true;
    const authRedirectUrl = `${process.env.REACT_APP_URL}/patient/treatment-plans/create`;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTreatmentCards = async () => {
      // Check if data is cached in localStorage
      const cachedData = localStorage.getItem("treatmentCards");

      // Use cached data if it exists
      if (cachedData) {
        setTreatmentCards(JSON.parse(cachedData));
        setLoading(false);
      } else {
        // Fetch data from the API if no cache is found
        try {
          const response = await api.get("/api/treatments");
          const treatments = response.data.data;

          // Store fetched data in localStorage
          localStorage.setItem("treatmentCards", JSON.stringify(treatments));

          setTreatmentCards(treatments);
        } catch (error) {
          console.error("Error fetching treatments:", error);
          navigate("/page/error");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTreatmentCards();
  }, [navigate]);

  //title Format
  const formatTitleForURL = (title) => {
    return title.replace(/ /g, "-").replace(/&/g, "and").replace(/or/g, "or");
  };

  if (loading) {
    return null; // Do not render anything while loading
  }
  return (
    <div>
      <Helmet>
        <title>Balanced | Pricing</title>
      </Helmet>
      <Header />
      <div className="pricing_page">
        <div className="pricing-hero-header-wrapper">
          <div className="container">
            <div className="pricing-hero-content">
              <p className="pricing-hero-subtitle">Pricing Page</p>
              <h2 className="pricing-hero-title">
                Discover Our Treatments With The Best Pricing
              </h2>
              <p className="pricing-hero-description">
                Check Out Our Pricing List For All Our Treatments. Our Prices
                Are The Best In The Market Compared With The Quality Of Work
                Provided.
              </p>
            </div>
          </div>
        </div>
        <div className="pricing-card-section-wrapper">
          <div className="pricing-card-section">
            <div className="container">
              <div className="row">
                {treatmentCards.slice(0, 4).map((treatment) => (
                  <div key={treatment.id} className="col-12 col-lg-6 mb-4">
                    <div className="pricing-card h-100 text-center">
                      <div className="pricing-card-body">
                        <div className="card-top">
                          <h5 className="pricing-card-title">
                            {treatment.name}
                          </h5>
                          <p className="pricing-card-text">
                            {treatment.description}
                          </p>
                          <Link
                            to={
                              isAuthenticated
                                ? authRedirectUrl
                                : `/questionnaire/${formatTitleForURL(
                                    treatment.name
                                  )}/${treatment.id}`
                            }
                            className="pricing-card-btn"
                          >
                            <p>Take Your Quiz →</p>
                          </Link>
                        </div>
                        <div className="pricing-card-price">
                          <p className="pricing-from">From</p>
                          <p className="pricing-card-price">
                            ${treatment.starting_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {treatmentCards[4] && (
                  <div className="col-12 mb-4">
                    <div className="pricing-card h-100 text-center">
                      <div className="pricing-card-body">
                        <div className="card-top">
                          <h5 className="pricing-card-title">
                            {treatmentCards[4].name}
                          </h5>
                          <p className="pricing-card-text">
                            {treatmentCards[4].description}
                          </p>
                          <Link
                            to={
                              isAuthenticated
                                ? authRedirectUrl
                                : `/questionnaire/${formatTitleForURL(
                                    treatmentCards[4].name
                                  )}/${treatmentCards[4].id}`
                            }
                            className="pricing-card-btn"
                          >
                            <p>Take Your Quiz →</p>
                          </Link>
                        </div>
                        <div className="pricing-card-price">
                          <p className="pricing-from">From</p>
                          <p className="pricing-card-price">
                            ${treatmentCards[4].starting_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {treatmentCards.slice(5, 9).map((treatment) => (
                  <div key={treatment.id} className="col-12 col-lg-6 mb-4">
                    <div className="pricing-card h-100 text-center">
                      <div className="pricing-card-body">
                        <div className="card-top">
                          <h5 className="pricing-card-title">
                            {treatment.name}
                          </h5>
                          <p className="pricing-card-text">
                            {treatment.description}
                          </p>
                          <Link
                            to={
                              isAuthenticated
                                ? authRedirectUrl
                                : `/questionnaire/${formatTitleForURL(
                                    treatment.name
                                  )}/${treatment.id}`
                            }
                            className="pricing-card-btn"
                          >
                            <p>Take Your Quiz →</p>
                          </Link>
                        </div>
                        <div className="pricing-card-price">
                          <p className="pricing-from">From</p>
                          <p className="pricing-card-price">
                            ${treatment.starting_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {treatmentCards[9] && (
                  <div className="col-12 mb-4">
                    <div className="pricing-card h-100 text-center">
                      <div className="pricing-card-body">
                        <div className="card-top">
                          <h5 className="pricing-card-title">
                            {treatmentCards[9].name}
                          </h5>
                          <p className="pricing-card-text">
                            {treatmentCards[9].description}
                          </p>
                          <Link
                            to={
                              isAuthenticated
                                ? authRedirectUrl
                                : `/questionnaire/${formatTitleForURL(
                                    treatmentCards[9].name
                                  )}/${treatmentCards[9].id}`
                            }
                            className="pricing-card-btn"
                          >
                            <p>Take Your Quiz →</p>
                          </Link>
                        </div>
                        <div className="pricing-card-price">
                          <p className="pricing-from">From</p>
                          <p className="pricing-card-price">
                            ${treatmentCards[9].starting_price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricing;
