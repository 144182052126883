import React, { useState, useEffect, useRef } from "react";
import FAQCard from "../components/FaqCard";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useParams, Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";

/**
 * FaqAnswers Page
 * This page is used to display FAQ answers based on categories. Users can search for FAQs,
 * filter results, and click on specific questions to view their answers. The component fetches
 * FAQ data dynamically based on the selected category from the URL parameters.
 */


function FaqAnswers() {
  // Get the category from the URL parameters
  const { category } = useParams();

  // State to hold FAQs and search query
  const [faqData, setFaqData] = useState(() => {
    // Try to load cached data on initial render
    const cached = localStorage.getItem("faqData");
    return cached ? JSON.parse(cached) : [];
  });
  const [categoryFaqs, setCategoryFaqs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [openQuestion, setOpenQuestion] = useState(null);

  // Reference to individual FAQ elements for scrolling
  const faqRefs = useRef({});

  // Hook for navigation
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    // Fetch FAQ data from the API
    const fetchData = async () => {
      try {
        // First check if we have cached data
        const cached = localStorage.getItem("faqData");
        if (cached) {
          const parsedData = JSON.parse(cached);
          if (isMounted) {
            setFaqData(parsedData);
          }
        }

        const response = await api.get("/api/faqs", {
          // Add caching headers
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        });

        console.log("Full Response",response.data.data);

        if (isMounted) {
          setFaqData(response.data.data);
          // Cache the new data
          localStorage.setItem("faqData", JSON.stringify(response.data.data));
        }
      } catch (error) {
        navigate("/page/error");
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [navigate]);

  // Filter FAQs based on category
  useEffect(() => {
    if (faqData.length > 0 && category) {
      const categoryData = faqData.find(
        (item) => item.name.toLowerCase() === category.toLowerCase()
      );
      
      if (categoryData) {
        setCategoryFaqs(categoryData.faqs);
      } else {
        setCategoryFaqs([]);
      }
    }
  }, [category, faqData]);

  /**
   * Handles the search query input and filters the FAQ data accordingly.
   * @param {Event} e - The search input event
   */

  // Handles the search functionality for filtering FAQs
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() !== "") {
      const results = faqData
        .map((category) => {
          // Find matching questions in the current category
          const matchingQuestions = category.faqs.filter((faq) =>
            faq.question.toLowerCase().includes(query)
          );

          // Only return categories that have matching questions
          if (matchingQuestions.length > 0) {
            return {
              category: category.name,
              faqs: matchingQuestions,
            };
          }
          return null;
        })
        .filter(Boolean); // Remove null entries

      setFilteredFaqs(results);
    } else {
      setFilteredFaqs([]);
    }
  };

  /**
   * Handles the result click from the search dropdown, navigating to the selected FAQ.
   * @param {string} category - The category of the FAQ
   * @param {string} question - The question that was clicked
   */
  const handleResultClick = (category, question) => {
    setSearchQuery("");
    setFilteredFaqs([]);
    setOpenQuestion(question); // Set the clicked question to open its matching card
    if (faqRefs.current[question]) {
      faqRefs.current[question].scrollIntoView({ behavior: "smooth" });
    }
    navigate(`/support/${category}`, { state: { question } });
  };

  /**
   * Handles form submission, which is disabled in this case (since it's just a search form).
   * @param {Event} e - The form submit event
   */
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const displayFaqs = searchQuery.trim() !== "" ? 
    (filteredFaqs[0]?.faqs || []) : 
    categoryFaqs;

  return (
    <div>
      <Helmet>
        <title>Balanced | FAQ</title>
      </Helmet>
      <Header />
      <div className="faq_page">
        <div className="faq-hero-header-wrapper">
          <div className="container">
            <div className="faq-hero-content">
              <h2 className="faq-hero-title">
                Find Answers To All Your Questions
              </h2>
              <form onSubmit={handleSubmit}>
                <div
                  className="form-outline faq-search-div"
                  data-mdb-input-init
                >
                  <input
                    type="search"
                    className="form-control faq-search-input"
                    id="datatable-search-input"
                    placeholder="Type your question"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </form>
              {/* Live Search Results */}
              {filteredFaqs.length > 0 && (
                <div className="faq-search-results">
                  <ul>
                    {filteredFaqs.map((categoryResult) => (
                      <li
                        key={categoryResult.categoryId}
                        className="first-child-li"
                      >
                        <strong>{categoryResult.category}</strong>
                        <ul>
                          {categoryResult.faqs.map((faq) => (
                            <li
                              key={faq.id}
                              onClick={() =>
                                handleResultClick(
                                  categoryResult.category,
                                  faq.question
                                )
                              }
                              className="second-child-li"
                              dangerouslySetInnerHTML={{
                                __html: faq.highlightedQuestion || faq.question,
                              }}
                            />
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="faq_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h4 className="faq_section_title mt-3 mb-5 text-align-center">
                  {" "}
                  <Link to="/support" style={{ color: "#000" }}>
                    Faq
                  </Link>{" "}
                  &gt; {category}
                </h4>
                <div className="row d-flex ">
                  <div className="col-lg-8 col-md-7">
                  {searchQuery.trim() === "" ? (
                      displayFaqs.length > 0 ? (
                        displayFaqs.map((Faq, index) => (
                          <div
                            className="faq_card_wrapper"
                            key={index}
                            ref={(el) => (faqRefs.current[Faq.question] = el)}
                          >
                            <FAQCard
                              question={Faq.question}
                              answer={Faq.answer}
                              isOpen={openQuestion === Faq.question}
                            />
                          </div>
                        ))
                      ) : (
                        <p>No FAQs found for this category. We will update it soon.</p>
                      )
                    ) : filteredFaqs.length > 0 ? (
                      filteredFaqs[0].faqs.map((faq, index) => (
                        <div
                          className="faq_card_wrapper"
                          key={index}
                          ref={(el) => (faqRefs.current[faq.question] = el)}
                        >
                          <FAQCard
                            question={faq.question}
                            answer={faq.answer}
                            isOpen={openQuestion === faq.question}
                          />
                        </div>
                      ))
                    ) : (
                      <p>No FAQs found for this search query.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FaqAnswers;
