import React, { useState, useEffect } from "react";

/**
 * FAQCard Component
 *
 * This component renders a frequently asked question card that can be
 * expanded and collapsed to show or hide the answer. The accordion behavior
 * is controlled by the local state, which is initialized by an external prop (`isOpenProp`).
 *
 * Props:
 * - question
 * - answer
 * - isOpen: A boolean prop to control whether the card is initially opened or closed.
 */

function FAQCard({ question, answer, isOpen: isOpenProp }) {
  // Local state to track whether the accordion is open or closed
  const [isOpen, setIsOpen] = useState(false);

  // Sync local state with the external `isOpenProp` whenever it changes
  useEffect(() => {
    setIsOpen(isOpenProp);
  }, [isOpenProp]);

  // Toggle function to expand/collapse the accordion
  const toggleAccordion = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="faq_card_wrapper">
      <div className="faq_card" onClick={toggleAccordion}>
        <div className="faq_header">
          <h5 className="faq_header_title">{question}</h5>
          <div className="faq_img_wrapper">
            <img
              src={require("..//assets/images/sortDown.png")}
              alt="Arrow"
              className={`faq_arrow ${isOpen ? "open" : ""}`}
            />
          </div>
        </div>
      </div>
      <p className={`faq_answer ${isOpen ? "openbox" : ""}`}>{answer}</p>
    </div>
  );
}

export default FAQCard;
