import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import FaqCategoryCard from "../components/FaqCategoryCard";
import { Helmet } from "react-helmet-async";
import api from "../Api/AuthApi";
function FAQ() {
  // State for managing the search query and filtered FAQs
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [faqData, setFaqData] = useState(() => {
    // Try to load cached data on initial render
    const cached = localStorage.getItem("faqData");
    return cached ? JSON.parse(cached) : [];
  });
  // React Router hook to navigate between pages
  const navigate = useNavigate();

  
  useEffect(() => {
    let isMounted = true;
    // Fetch FAQ data from the API
    const fetchData = async () => {
      try {
        // First check if we have cached data
        const cached = localStorage.getItem("faqData");
        if (cached) {
          const parsedData = JSON.parse(cached);
          if (isMounted) {
            setFaqData(parsedData);
          }
        }

        const response = await api.get("/api/faqs", {
          // Add caching headers
          headers: {
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        });

        console.log(response)
        if (isMounted) {
          setFaqData(response.data.data);
          // Cache the new data
          localStorage.setItem("faqData", JSON.stringify(response.data.data));
        }
      } catch (error) {
        navigate("/page/error");
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };

  }, [navigate]);

  // Handles the search functionality for filtering FAQs
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    if (query.trim() !== "") {
      const results = faqData
        .map((category) => {
          // Find matching questions in the current category
          const matchingQuestions = category.faqs.filter((faq) =>
            faq.question.toLowerCase().includes(query)
          );

          // Only return categories that have matching questions
          if (matchingQuestions.length > 0) {
            return {
              category: category.name, // Using 'name' instead of 'category'
              faqs: matchingQuestions,
            };
          }
          return null;
        })
        .filter(Boolean); // Remove null entries

      setFilteredFaqs(results);
    } else {
      setFilteredFaqs([]);
    }
  };

  // Navigate to a specific question in the selected category
  const handleResultClick = (category, question) => {
    navigate(`/support/${category}`, { state: { question } });
  };

  // Prevent the form from it's default submitting
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // Navigate to a specific FAQ category page
  const handleCategoryClick = (category) => {
    navigate(`/faq/${category}`);
  };

  return (
    <div>
      <Helmet>
        <title>Balanced | FAQ</title>
      </Helmet>
      <Header />
      <div className="faq_page">
        <div className="faq-hero-header-wrapper">
          <div className="container">
            <div className="faq-hero-content">
              <h2 className="faq-hero-title">
                Find Answers To All Your Questions
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="form-outline faq-search-div">
                  <input
                    type="search"
                    className="form-control faq-search-input"
                    placeholder="Type your question"
                    value={searchQuery}
                    onChange={handleSearch}
                  />
                </div>
              </form>

              {/* Live Search Results */}
              {filteredFaqs.length > 0 && (
                <div className="faq-search-results">
                  <ul>
                    {filteredFaqs.map((categoryResult) => (
                      <li
                        key={categoryResult.categoryId}
                        className="first-child-li"
                      >
                        <strong>{categoryResult.category}</strong>
                        <ul>
                          {categoryResult.faqs.map((faq) => (
                            <li
                              key={faq.id}
                              onClick={() =>
                                handleResultClick(
                                  categoryResult.category,
                                  faq.question
                                )
                              }
                              className="second-child-li"
                              dangerouslySetInnerHTML={{
                                __html: faq.highlightedQuestion || faq.question,
                              }}
                            />
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="faq-categories">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 className="faq-categories-title">Popular Topics</h3>
                <div className="row">
                  {faqData
                    .sort((a, b) => a.id - b.id) // Sort by id
                    .map((categoryData) => (
                      <div key={categoryData.id} className="col-lg-4 col-md-6">
                        <FaqCategoryCard
                          image={require(`../assets/images/${categoryData.image}`)}
                          title={categoryData.name}
                          description={categoryData.description}
                          onClick={() => handleCategoryClick(categoryData.name)}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
