// src/App.js
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css.map";
import "../assets/styles.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import GetStarted from "./GetStarted";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetLinkSent from "../components/ResetMessage";
import AdminLogin from "./AdminLogin";
import NotFound from "../components/NotFound";
import HowItWorks from "./HowItWork";
import AboutUs from "./AboutUs";
import Pricing from "./Pricing";
import ContactUs from "./ContactUs";
import { AuthProvider, useAuth } from "..//Auth/Auth";
import FAQ from "./FAQ";
import FaqAnswers from "./FaqAnswers";
import Doctors from "./Doctors";
import Questionairre from "./Questionnaire";
import SurveyQuestions from "../components/SurveyQuestions";
import RefundPolicy from "./RefundPolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions";
import ErrorPage from "./Error";
import ErrorBoundary from "../components/ErrorBoundary";
import ResetPassword from "../components/ResetPassword";
import SuccessfulReset from "../components/SuccessfulReset";
import ContactMessage from "../components/ContactMessage";

function App() {
  const { isAuthenticated } = useAuth();

  // Function to check if authState in localStorage is true
  const isAuthStateValid = () => {
    const authState = JSON.parse(localStorage.getItem("authState"));
    return authState && authState.isAuthenticated === true;
  };

  // Protected route wrapper
  const ProtectedRoute = ({ children }) => {
    if (isAuthenticated && isAuthStateValid()) {
      return <Navigate to="/" />; // Redirect to homepage if authenticated and valid
    } else if (!isAuthenticated) {
      return <Navigate to="/admin/login" />; // Redirect to login if not authenticated or valid
    }
    return children;
  };

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Home /> : <Navigate to="/admin/login" />}
        />
        <Route
          path="/users/login"
          element={
            <ProtectedRoute>
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/password/new"
          element={
            <ProtectedRoute>
              <ForgotPassword />
            </ProtectedRoute>
          }
        />
        <Route path="/password-reset/:token" element={<ResetPassword />} />
        <Route
          path="/users/reset-link-sent"
          element={
            <ProtectedRoute>
              <ResetLinkSent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/reset-password-done"
          element={
            isAuthStateValid() ? <SuccessfulReset /> : <Navigate to="/" />
          }
        />
        <Route
          path="/admin/login"
          element={isAuthenticated ? <Navigate to="/" /> : <AdminLogin />}
        />
        <Route
          path="/quizzes"
          element={
            <ProtectedRoute>
              <GetStarted />
            </ProtectedRoute>
          }
        />
        <Route
          path="/how-it-works"
          element={isAuthenticated ? <HowItWorks /> : <AdminLogin />}
        />
        <Route
          path="/our-story"
          element={isAuthenticated ? <AboutUs /> : <AdminLogin />}
        />
        <Route
          path="/pricing"
          element={isAuthenticated ? <Pricing /> : <AdminLogin />}
        />
        <Route
          path="/contact-us"
          element={isAuthenticated ? <ContactUs /> : <AdminLogin />}
        />
        <Route
          path="/contact-us/message-sent"
          element={isAuthenticated ? <ContactMessage /> : <AdminLogin />}
        />
        <Route
          path="/support"
          element={isAuthenticated ? <FAQ /> : <AdminLogin />}
        />
        <Route
          path="/page/error"
          element={isAuthenticated ? <ErrorPage /> : <AdminLogin />}
        />
        <Route
          path="/support/:category"
          element={isAuthenticated ? <FaqAnswers /> : <AdminLogin />}
        />

        <Route
          path="/our-doctors"
          element={isAuthenticated ? <Doctors /> : <AdminLogin />}
        />

        <Route
          path="/questionnaire/:treatmentName/:id"
          element={
            <ProtectedRoute>
              <Questionairre />
            </ProtectedRoute>
          }
        />
        <Route
          path="/questionnaire/:treatmentName/:id/start-quiz/?"
          element={
            <ProtectedRoute>
              <SurveyQuestions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/privacy-policy"
          element={isAuthenticated ? <PrivacyPolicy /> : <AdminLogin />}
        />

        <Route
          path="/terms-and-conditions"
          element={isAuthenticated ? <TermsAndConditions /> : <AdminLogin />}
        />

        <Route
          path="/refund-policy"
          element={isAuthenticated ? <RefundPolicy /> : <AdminLogin />}
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

const AppWithProvider = () => (
  <AuthProvider>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </AuthProvider>
);

export default AppWithProvider;
