import React from "react";
import ErrorPage from "../pages/Error";

/**
 * ErrorBoundary is a React component that catches JavaScript errors anywhere in its child component tree,
 * logs those errors, and displays a fallback UI (error page) instead of crashing the app.
 *
 */

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * This lifecycle method is called when an error is thrown in a child component.
   * It updates the component's state to indicate that an error has occurred,
   * which will trigger the fallback UI (ErrorPage) to be rendered.
   *
   * @param {Error} error - The error that was thrown
   * @returns {Object} - The updated state object
   */

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  /**
   * This lifecycle method is used to log error details.
   * It captures both the error and additional information about where the error occurred.
   *
   * @param {Error} error - The error object thrown in the component tree
   * @param {Object} errorInfo - An object containing details about which component in the tree caused the error
   */
  

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <ErrorPage />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
