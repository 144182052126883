import React from "react";
import { useLocation, Link } from "react-router-dom";

/**
 * ResetLinkSent Component
 *
 * This component is responsible for displaying a confirmation message
 * to the user once a password reset link has been sent to their email.
 * It fetches the email address from the location state and provides
 * links for the user to return to the login page.
 *
 * @component
 * @example
 * return (
 *   <ResetLinkSent />
 * )
 */

const ResetLinkSent = () => {
  // Extract email from location state passed from the previous page.
  const location = useLocation();
  const { email } = location.state || {};

  return (
    <div className="login-wrapper reset-message">
      <div className="container">
        <Link to="/">
          <div className="login-header">
            <img
              src={require("../assets/images/balanced-logo.png")}
              alt="Logo"
            />
          </div>
        </Link>
        <div className="login-container">
          <div className="login-card">
            <div className="login-body">
              <div className="reset-link-sent-card">
                <p>
                  A reset link has been sent to your email <span>{email}</span>{" "}
                  Please reset your password and{" "}
                  <Link to="/users/login" className="link">
                    login
                  </Link>{" "}
                  again.
                </p>
                <Link to="/users/login" className="btn btn-primary">
                  Go to Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetLinkSent;
