import React from "react";
import App from "./pages/App";
import "./loadGoogleMaps";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";

// window.addEventListener("unhandledrejection", (event) => {

// });

// window.onerror = (message, source, lineno, colno, error) => {
//   logToServer("error", message, { source, lineno, colno, error });
// };

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>
);
