import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

/**
 * TestimonialCard component that displays a client's testimonial.
 * It includes the testimonial title, review text, client name,
 * and a star rating image, all with animations.
 *
 * props:
 * title - The title of the testimonial
 * review - The review text from the client
 * name - The name of the client giving the testimonial
 */

function TestimonialCard(props) {
  // Destructuring props to extract title, review, and name

  const { title, review, name } = props;
  return (
    <div
      className="testimonial_card"
      data-aos="zoom-in-up"
      data-aos-duration="1000"
    >
      <div className="testimonial_card_content">
        <h4 className="testimonial_card_title">{title}</h4>
        <p className="testimonial_card_text">{review}</p>
      </div>

      <div className="closing_info">
        <h5 className="client_name">{name}</h5>
        <img
          src={require("..//assets/images/stars.png")}
          alt="Testimonial"
          className="testimonial_img"
        />
      </div>
    </div>
  );
}

export default TestimonialCard;
